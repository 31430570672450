import React, { useEffect } from "react";
import CustomTable from "utils/appointment/customTable";
import CustomCard from "utils/customCard";
import { mockOrderData, ordersColumns } from "./ordersColumns";
import CustomButton from "utils/appointment/customButton";
import { useEcommerce } from "zustand/store/ecommerceStore";

const Orders = () => {
  const { loading, fetchOrders, ordersData } = useEcommerce((state) => ({
    loading: state.loading,
    fetchOrders: state.fetchOrders,
    ordersData: state.ordersData,
  }));

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);
  const renderAction = () => {
    return {
      key: "adr",
      title: "Action",
      render: (order) => (
        <CustomButton
          textbutton="Examiner"
          handleClick={() => {}}
          type="primary"
        />
      ),
    };
  };

  return (
    <CustomCard
      loading={loading}
      title="Liste des commandes"
      children={
        <CustomTable
          dataList={ordersData}
          columns={[...ordersColumns, renderAction()]}
        />
      }
    />
  );
};

export default Orders;
