import {
  DashboardOutlined,
  UserOutlined,
  BugOutlined,
  FileOutlined,
  ExceptionOutlined,
  FileDoneOutlined,
  BookOutlined,
  CloudUploadOutlined,
  VideoCameraOutlined,
  ThunderboltOutlined,
  MoneyCollectOutlined,
  DollarOutlined,
  NotificationOutlined,
  BankOutlined,
  FileImageOutlined,
  ConsoleSqlOutlined,
  AlignCenterOutlined,
  AppstoreOutlined,
  CodeOutlined,
  HistoryOutlined,
  UnorderedListOutlined,
  MedicineBoxOutlined,
  ExperimentOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  MediumCircleFilled,
  SolutionOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  ScheduleOutlined,
  ReadOutlined,
  HddOutlined,
  HddFilled,
  SketchOutlined,
  FileImageFilled,
  ScanOutlined,
  CustomerServiceOutlined,
  SmileOutlined,
  TagsOutlined,
  DesktopOutlined,
  PhoneOutlined,
  PhoneTwoTone,
  VideoCameraAddOutlined,
  FileProtectOutlined,
  ContactsOutlined,
  DatabaseFilled,
  DatabaseOutlined,
  AccountBookFilled,
  AccountBookOutlined,
  MoneyCollectFilled,
  CalculatorOutlined,
} from "@ant-design/icons";

import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const usersRelatedNavTree = [
  {
    key: "doctors",
    path: `${APP_PREFIX_PATH}/doctors`,
    title: " ",
    // // breadcrumb: true,
    submenu: [      
      {
        key: "medecins.notifiaction",
        path: `${APP_PREFIX_PATH}/notification`,
        title: "medecins.notifiaction",
        icon: NotificationOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "users",
        path: `${APP_PREFIX_PATH}/enterprise`,
        title:  "sidenav.users",
        icon: UsergroupAddOutlined,
        breadcrumb: true,
        submenu:[
          {
            key: "demande de creation",
            path: `${APP_PREFIX_PATH}/requests`,
            title: "creation de compte",
            icon: UsergroupAddOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "medecins",
            path: `${APP_PREFIX_PATH}/doctors`,
            title: "medecins",
            icon: UserOutlined,
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "healthprofessional",
            path: `${APP_PREFIX_PATH}/healthprofessional`,
            title: "professionnel de santé",
            icon: MedicineBoxOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "patients",
            path: `${APP_PREFIX_PATH}/patients`,
            title: "Patients",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ]
      },
      {
        key: "consultation",
        path: `${APP_PREFIX_PATH}/consultation`,
        title: "Télé-expertise ",
        icon: SolutionOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "consultation.wait",
            path: `${APP_PREFIX_PATH}/consultation/list/wait`,
            title: "En Attente",
            icon: ExceptionOutlined,
            breadcrumb: false,
            submenu: [],
          },
        
          {
            key: "consultation.terminate",
            path: `${APP_PREFIX_PATH}/consultation/list/terminate`,
            title: "Terminées",
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "consultation.new",
            path: `${APP_PREFIX_PATH}/consultation/list/new`,
            title: "Annulés",
            icon: FileOutlined,
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "typical_responses",
          //   path: `${APP_PREFIX_PATH}/typicals-responses`,
          //   title: "Reponse Typique",
          //   icon: FileOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
      
      {
        key: "imagerie-medical",
        path: `${APP_PREFIX_PATH}/image`,
        title: "Imagerie médicale ",
        icon: ScanOutlined,
        breadcrumb: false,
        submenu :[
          {
            key: "consultation.dicoms",
            path: `${APP_PREFIX_PATH}/dicoms/list`,
            title: "consultation.dicoms",
            icon: FileImageOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "folder_creation_requests",
            path: `${APP_PREFIX_PATH}/folder-creation-requests/list`,
            title: "folder_creation_requests",
            icon: CloudUploadOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ]
    },
    {
      key: "teleconsultation",
      title: "Téléconsultation",
      breadcrumb: false,
      icon: ConsoleSqlOutlined,
      submenu: [
        {
          key: "teleconsultation.list",
          path: `${APP_PREFIX_PATH}/tele-consultation/list`,
          title: "Astreinte Téléconsultation",
          icon: ConsoleSqlOutlined,
          breadcrumb: false,
          submenu: [],
        },
      ],
    },
    {
      key: "yata_patient",
        path: `${APP_PREFIX_PATH}/patients`,
        title: "Services Patient",
        icon: TagsOutlined,
        breadcrumb: false,
        submenu:[
          {
            key: "appointments.online",
            path: `${APP_PREFIX_PATH}/appointment/online`,
            title: "Rendez-vous en ligne",
            icon: VideoCameraAddOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "appointments.office",
            path: `${APP_PREFIX_PATH}/appointment/office`,
            title: "Rendez-vous au cabinet",
            icon: ScheduleOutlined ,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "homecare",
            path: `${APP_PREFIX_PATH}/home-care-requests`,
            title: "Soins a domicile",
            icon: HomeOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "appointments",
            path: `${APP_PREFIX_PATH}/in-home-sampling-requests`,
            title: "Prelevement a domicile",
            icon: MedicineBoxOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ]
    },
    {
      key: "biocapteur",
      path: `${APP_PREFIX_PATH}/enterprise`,
      title: "Biocapteurs",
      icon: UnorderedListOutlined,
      breadcrumb: true,
      submenu:[
        {
          key: "biosensor.listPatient",
          path: `${APP_PREFIX_PATH}/biosensors/patches`,
          title: "	Liste des commandes ",
          icon: MedicineBoxOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "biosensor.listContract",
          path: `${APP_PREFIX_PATH}/biosensors/contracts`,
          title: "Liste des clients ",
          icon: ContactsOutlined,
          breadcrumb: false,
          submenu: [],
        },
        
      ]
    },
    {
      key: "invoices",
      path: "",
      title: "Caisse",
      icon: DollarOutlined,
      breadcrumb: true,
      submenu: [
       
        {
          key: "pay",
          path: `${APP_PREFIX_PATH}/appointment/payments/listAppointments`,
          title: "Paiements ",
          icon: DollarOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "pharmacie",
          path: `${APP_PREFIX_PATH}/appointment/payments/listAppointments`,
          title: "commandes Parapharmacie ",
          icon: MedicineBoxOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "cmf",
          path: `${APP_PREFIX_PATH}/appointment/payments/medical`,
          title: "commandes Consommables médicales",
          icon: MedicineBoxOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "claims.list",
          path: `${APP_PREFIX_PATH}/claims-request`,
          title: "Reclammation",
          icon: DatabaseOutlined,
          breadcrumb: false,
          submenu: [],
        },
      ],
    },
    {
      key: "eprescription",
      path: `${APP_PREFIX_PATH}/eprescription`,
      title: "eprescription",
      icon: UnorderedListOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "eprescription.ordenance",
          path: `${APP_PREFIX_PATH}/eprescription/new-medics/list`,
          title: "eprescription.newMedics",
          icon: MedicineBoxOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "eprescription.type",
          path: `${APP_PREFIX_PATH}/eprescription/typical/list`,
          title: "eprescription.typical",
          icon: ExceptionOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "eprescription.history",
          path: `${APP_PREFIX_PATH}/eprescription/history/list`,
          title: "eprescription.history",
          icon: HistoryOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "typical_responses",
          path: `${APP_PREFIX_PATH}/typicals-responses`,
          title: "Comptes Rendus Types",
          icon: FileOutlined,
          breadcrumb: false,
          submenu: [],
        },
      ],
    },
      {
        key: "gestion_enterprise",
        path: `${APP_PREFIX_PATH}/enterprise`,
        title: "Établissement de santé ",
        icon: BankOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "approve_enterprise",
            path: `${APP_PREFIX_PATH}/enterprise/approve_enterprise`,
            title: "Approuver la demande",
            icon: BankOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "enterprise",
            path: `${APP_PREFIX_PATH}/enterprise`,
            title: "Liste des Etablissements ",
            icon: BankOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "laboratory",
            path: `${APP_PREFIX_PATH}/laboratory`,
            title: "Laboratoire",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "count",
        title: "Comptabilte",
        breadcrumb: false,
        icon:CalculatorOutlined,
        submenu: [
          {
          key: "#methods",
          path: `${APP_PREFIX_PATH}/payments/payment-methods`,
          title: "Méthodes de paiement",
          icon: DollarOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "pay",
          path: `${APP_PREFIX_PATH}/appointment/payments/listAppointments`,
          title: "Paiements ",
          icon: DollarOutlined,
          breadcrumb: false,
          submenu: [],
        },
          {
            key: "count.transaction",
            path: `${APP_PREFIX_PATH}/transaction`,
            title: "count.transaction",
            icon: DollarOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "count.settlements",
            path: `${APP_PREFIX_PATH}/settlements`,
            title: "count.settlements",
            icon: DollarOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      
     
      // {
      //   key: "appointments",
      //   path: `${APP_PREFIX_PATH}/appointment`,
      //   title: "Rendez-vous",
      //   icon: ScheduleOutlined,
      //   breadcrumb: true,
      //   submenu: [
         
      //   ],
      // },
      // {
      //   key: "homeServices",
      //   path: `${APP_PREFIX_PATH}/home-care-requests`,
      //   title: "Prestation a domicile",
      //   icon: HddOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //   ],
      // },
      // {
      //   key: "orderManagement",
      //   path: `${APP_PREFIX_PATH}/order-management`,
      //   title: "Gestion des commandes & produit",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "order",
      //       path: `${APP_PREFIX_PATH}/order-requests`,
      //       title: "Liste des commandes",
      //       icon: DashboardOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "categories",
      //       path: `${APP_PREFIX_PATH}/categories`,
      //       title: "Gestion des categories",
      //       icon: DashboardOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "produits",
      //       path: `${APP_PREFIX_PATH}/products`,
      //       title: "Gestion des produits",
      //       icon: DashboardOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
     
      // {
      //   key: "medicalExams",
      //   path: `${APP_PREFIX_PATH}/medical-exams`,
      //   title: "Transport d'examen",
      //   icon: UnorderedListOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "medicalExams.newDemandes",
      //       path: `${APP_PREFIX_PATH}/medical-exams/new`,
      //       title: "Nouvelle Demande",
      //       icon: MedicineBoxOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "medicalExams.completed",
      //       path: `${APP_PREFIX_PATH}/medical-exams/completed`,
      //       title: "Termine",
      //       icon: FileProtectOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
      // {
      //   key: "count",
      //   title: "count",
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "count.payment",
      //       path: `${APP_PREFIX_PATH}/payments`,
      //       title: "count.payment",
      //       icon: MoneyCollectOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "count.transaction",
      //       path: `${APP_PREFIX_PATH}/transaction`,
      //       title: "count.transaction",
      //       icon: DollarOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "count.settlements",
      //       path: `${APP_PREFIX_PATH}/settlements`,
      //       title: "count.settlements",
      //       icon: DollarOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
      // {
      //   key: "biosensor",
      //   path: `${APP_PREFIX_PATH}/biosensors`,
      //   title: "biosensor",
      //   icon: UnorderedListOutlined,
      //   breadcrumb: false,
      //   submenu: [
     
      //   ],
      // },
      // {
      //   key: "symposium",
      //   title: "symposium",
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "symposium.article",
      //       path: `${APP_PREFIX_PATH}/articles`,
      //       title: "symposium.article",
      //       icon: BookOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "symposium.videos",
      //       path: `${APP_PREFIX_PATH}/videos`,
      //       title: "symposium.videos",
      //       icon: VideoCameraOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "symposium.publicity",
      //       path: `${APP_PREFIX_PATH}/publicity`,
      //       title: "symposium.publicity",
      //       icon: ThunderboltOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...usersRelatedNavTree,
];

export default navigationConfig;
