import React from 'react';
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { connect } from 'react-redux';
import utils from 'utils';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP;

  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return 'auto';
  }
  return navCollapsed ? `${SIDE_NAV_COLLAPSED_WIDTH}px` : `${SIDE_NAV_WIDTH}px`;
}

const getLogoSrc = (props) => {
  const { navCollapsed, logoType } = props;
  return logoType === 'light'
    ? (navCollapsed ? '/img/logo-sm-yata.png' : '/img/logo1.png')
    : (navCollapsed ? '/img/logo-sm-yata.png' : '/img/logo1.png');
}

const getLogoDisplayClass = (isMobile, mobileLogo) => {
  return isMobile && !mobileLogo ? 'd-none' : 'logo';
}

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const { navCollapsed } = props; // Destructure navCollapsed from props

  return (
    <div
      className={getLogoDisplayClass(isMobile, props.mobileLogo)}
      style={{
        width: getLogoWidthGutter(props, isMobile),
        display: 'flex',
        alignItems: 'center', // Ensures image and text are vertically centered
      }}
    >
      <img
        src={getLogoSrc(props)}
        alt={`${APP_NAME} logo`}
        style={{
          width: '40px',
          maxWidth:"300px",
          height:"40px",
          boxShadow: '0 3px 4px rgba(0, 0, 0, 0.1)',
          borderColor: 'rgba(180, 180, 180)',
          border: '0.5px solid rgb(195, 198, 201)',
          borderRadius:'10%'

        }}
      />
      {!navCollapsed && ( // Use navCollapsed from props
        <img
          src="/img/yata2.png"
          alt="Additional Logo"
          style={{
            width: '140px',
            height: '70%',
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(Logo);
